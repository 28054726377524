<template>
  <div class="header">
    <div class="icon">
      <img src="https://img.wb.qiuxs.com/icon.png" width="50" height="50" />
      <span>怀真 CMS</span>
    </div>

    <div class="user-info">
      <el-popover
        popper-class="user-info-popover"
        placement="bottom"
        width="230"
        trigger="click"
      >
        <div>
          <div>账号:&nbsp;{{ userInfo.loginId }}</div>
          <div>角色:&nbsp;{{ userInfo.roleNames }}</div>
          <div>登录时间:&nbsp;{{ userInfo.loginTime }}</div>
          <div class="bottom-toolbar">
            <el-button class="logout-btn" type="text" @click="logout">
              安全退出
            </el-button>
          </div>
        </div>

        <span slot="reference">
          <span class="user-name">{{ userInfo && userInfo.name }}</span>
        </span>
      </el-popover>
    </div>

    <el-popover
      popper-class="app-switch-popover"
      placement="bottom"
      width="230"
      trigger="click"
    >
      <div class="title">
        应用列表
        <i
          v-bind:class="{
            'el-icon-refresh': !this.loading,
            'el-icon-loading': this.loading,
          }"
          @click="loadAppList"
        ></i>
      </div>
      <ul class="app-list">
        <li
          v-for="app in this.appList"
          :key="app.id"
          v-bind:class="{
            'active-li': currentAppId === app.id,
            'unauthed-li': app.authorizationStatus == 0,
          }"
          @click="switchApp(app)"
        >
          <el-tag size="mini" type="primary">{{ app.typeName }}</el-tag>
          <span style="margin-left: 5px">{{ app.appName }}</span>
          <i v-if="currentAppId === app.id" class="el-icon-check"> </i>
        </li>
      </ul>

      <div slot="reference" class="app-switch">
        当前应用: <span>{{ currentAppName }}</span>
      </div>
    </el-popover>

    <div style="line-height: 60px; float: right; margin-right: 1rem;">
      <el-link
        type="primary"
        :href="`/new/authorize?sessionId=${userInfo.sessionId}`"
        target="_blank"
        >访问新版</el-link
      >
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapMutations } = createNamespacedHelpers("userStore");
const { mapMutations: menuStoreMutations } =
  createNamespacedHelpers("menuStore");

export default {
  name: "my-header",
  data() {
    return {
      loading: false,
      appList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "currentAppId", "currentAppName"]),
  },
  methods: {
    async loadAppList() {
      try {
        this.loading = true;
        let data = await this.$http.doApi(
          "fw-wx-authorizer-findAuthorizerAppList",
          { flag: 1 }
        );
        this.appList = data.rows;
      } finally {
        this.loading = false;
      }
    },
    async switchApp(app) {
      if (this.currentAppId === app.id) {
        return;
      }
      try {
        let userInfo = await this.$http.doApi("fw-cms-user-switch-app", {
          appId: app.id,
        });
        this.setUserInfo(userInfo);
        this.$eventBus.$emit("currentAppChanged", userInfo.unitId);
      } catch (e) {
        console.log(e);
      }
    },
    async logout() {
      try {
        await this.$http.doApiWithOptions(
          "fw-cms-user-logout",
          {},
          {},
          {
            showError: false,
          }
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.clearUserInfo();
        this.clearMenuInfo();
        window.location.href = window.location.origin + "/login";
      }
    },
    ...mapMutations(["clearUserInfo", "setUserInfo"]),
    ...menuStoreMutations(["clearMenuInfo"]),
  },
  created() {
    this.loadAppList();
  },
};
</script>

<style lang="less">
.header {
  .icon {
    width: 180px;
    height: 60px;
    line-height: 60px;
    display: inline-block;

    img {
      vertical-align: middle;
      margin-right: 1rem;
      border-radius: 5px;
    }

    span {
      color: #ff7b00;
    }
  }

  .app-switch {
    float: right;
    color: #ffffff;
    line-height: calc(60px - 2rem);
    margin: 1rem 0;
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 1.5px #cccccc solid;
    cursor: pointer;

    span {
      margin-left: left;
      text-decoration: underline;
    }
  }

  .user-info {
    float: right;
    line-height: 60px;
    color: #ffffff;

    .user-name {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.user-info-popover {
  .logout-btn {
    float: right;
  }
}

.app-switch-popover {
  .title {
    border-bottom: 1px #cccccc solid;
    padding: 0.2rem 0.3rem;

    i {
      float: right;
      color: #409eff;
      cursor: pointer;
    }
  }

  .app-list {
    list-style: none;
    padding-left: 0;
    margin: 0px;
    li {
      margin: 0.3rem 0;
      cursor: pointer;
      padding: 0.3rem 0.8rem;

      border: 1px #cccccc solid;
      border-radius: 5px;
      background-color: #e2e2e2;

      i {
        float: right;
      }
    }

    li:hover {
      border: 1px solid red;
      transform: scale(1.01);
      transition: 300ms;
    }

    .active-li {
      background-color: #ffedb3;
    }

    .unauthed-li {
      background-color: #cccccc;
    }
  }
}
</style>